<template>
    <div class="daily">
        <div class="daily-content">
            <div class="select-topic">
                <div class="option-header">
                    <div class="select-wrap">
                        <div
                                class="select-type select-btn"
                                v-for="(v ,i) in selectList"
                                @click="checkQueryType(v.id)"
                                :class="{'selected-active':checkQueryIdx == v.id }"
                                :key="i"
                        >
                            <span v-text="v.val"></span>
                        </div>
                    </div>
                </div>
                <div class="option-body">
                    <div class="select-date clear" @click="openPicker()" v-show="checkQueryIdx != 0">
                        <div class="date left" v-text="dateObj.date"></div>
                        <div class="drop-down-btn left"></div>
                    </div>
                    <div class="select-country" v-if="hasQgPerm && hasCenter">
                        <div
                                class="select-txt selected-country"
                                :class="[selectAreaType == 0 ?'whiteFont' : '']"
                                @click="setActiveBySelected(0)"
                        >全国
                        </div>
                        <div
                                class="select-txt selected-center"
                                :class="[selectAreaType == 1 ?'whiteFont' : '']"
                                @click="setActiveBySelected(1)"
                        >本中心
                        </div>
                        <div class="check-btn" :class="'check-btn-run-'+selectAreaType"></div>
                    </div>
                    <div class="select-country show-center-box" v-if="!hasQgPerm && hasCenter">
                        <div
                                class="select-txt selected-center show-center"
                                :class="[selectAreaType == 1 ?'whiteFont' : '']"
                                @click="setActiveBySelected(1)"
                        >本中心
                        </div>
                        <div class="check-btn" :class="'check-btn-run-'+selectAreaType"></div>
                    </div>
                    <div class="select-country show-center-box" v-if="hasQgPerm && !hasCenter">
                        <div
                                class="select-txt selected-center show-center"
                                :class="[selectAreaType == 0 ?'whiteFont' : '']"
                                @click="setActiveBySelected(0)"
                        >全国
                        </div>
                        <div class="check-btn check-btn-run-1"></div>
                    </div>
                </div>
                <div v-show="checkQueryIdx" class="option-bottom">
                    <ul class="sales-txt-wrap clear">
                        <li class="sales-text-center-line"></li>
                        <li class="sales-text-center-line2"></li>
                        <li class="sales-text-bottom-line" :class="'check-line-run-' + selectSalesType"></li>
                        <li class="sales-type" @click="setClassNameBySelectSales(0)">
                            <p>销售额</p>
                            <p v-text="saleAmt"></p>
                        </li>
                        <li class="sales-type" @click="setClassNameBySelectSales(2)">
                            <p>期销售额</p>
                            <p v-text="drawCnt"></p>
                        </li>
                        <li class="sales-type" @click="setClassNameBySelectSales(1)">
                            <p>销售票数</p>
                            <p v-text="saleCnt"></p>
                        </li>
                    </ul>
                </div>
                <div v-show="!checkQueryIdx" class="option-bottom">
                    <ul class="sales-txt-wrap clear">
                        <li class="sales-text-center-line check-type-line"></li>
                        <li class="sales-text-bottom-line check-type-bottom-line" :class="'check-run-' + selectSalesType"></li>
                        <li class="sales-type" @click="setClassNameBySelectSales(0)">
                            <p>销售额</p>
                            <p v-text="saleAmt"></p>
                        </li>
                        <li class="sales-type" @click="setClassNameBySelectSales(1)">
                            <p>销售票数</p>
                            <p v-text="saleCnt"></p>
                        </li>
                    </ul>
                </div>
                <mt-datetime-picker
                        ref="picker"
                        v-model="pickerVisible"
                        type="date"
                        year-format="{value} 年"
                        month-format="{value} 月"
                        @confirm="handleConfirm()"
                        :endDate="getYesterday"
                ></mt-datetime-picker>
            </div>
            <!--销量统计-->
            <moduleTitle :title="salesTit"></moduleTitle>
            <salesLine :option="SalesOptin" @showSalesMask="showSalesMask" :loading="SalesloadingType"
                       :DataType="SalesHasDataType"
                       :salesCanvasSize="salesCanvasSize"></salesLine>
            <!--地区统计折线-->
            <moduleTitle :title="areaTit"></moduleTitle>
            <salesLine :option="AreaOptin" :salesCanvasSize="AreaCanvasSize" :loading="ArealoadingType"
                       :DataType="AreaHasDataType"></salesLine>
            <!--地区统计饼状图-->
            <div class="dataBox">
                <div class="dataBox_0 clear">
                    <div class="clear" style="margin-left: 1.5rem">
                        <salesLine :option="cityOptin" :salesCanvasSize="cityCanvasSize" :loading="ArealoadingType"
                                   :DataType="CityHasDataType"></salesLine>
                    </div>
                    <div class="chart_desc">
                        <div v-for="(v,i) in locArr" v-show="i < showlocIndex" :key="i">
                            <i :style="{background:colorArr[i]}"></i>
                            <span class="name_city" v-text="v.name"></span>
                            <span class="num_city"
                                  v-text="numFormat(v.cnt) + ( unitArr[selectSalesType])"></span>
                            <span class="num_percent" v-text="getPercent(v.cnt,allLocSales)"></span>
                        </div>
                        <div class="linkToAll" @click="showAllLoc" v-if="this.locArr.length >3" v-text="tips"></div>
                    </div>
                </div>
            </div>
            <!--游戏类型计折线-->
            <moduleTitle :title="gameTit"></moduleTitle>
            <salesLine :option="GameOptin" :salesCanvasSize="GameCanvasSize" :loading="GameloadingType"
                       :DataType="GameHasDataType"></salesLine>
            <div class="game-box">
                <salesLine :option="GameOptin2" :salesCanvasSize="GameCanvasSize2" :loading="GameloadingType"
                           :DataType="Game2HasDataType"></salesLine>
            </div>
            <!--过关方式统计柱状-->
            <moduleTitle :title="ggtjTit"></moduleTitle>
            <div class="ggtj_box">
                <ul>
					<li v-for="(v ,i) in ggtjList"
						@click="checkggtjType(v.id)"
						:class="{'selected_active':ggtjIdx == v.id }"
						:key="i" v-text="v.val"></li>
				</ul>
				<div class="ggtjeCharts" style="width:37.5rem;height:28.5rem;">
					<salesLine :option="ggtjOption" :salesCanvasSize="ggtjCanvasSize" :loading="ggtjloadingType" :DataType="ggtjDataType"></salesLine>
				</div>
            </div>
            <!--赛事统计柱状-->
            <moduleTitle :title="sstjTit"></moduleTitle>
            <p style="text-align:right;line-height:2rem;margin:1rem">不包含胜负过关玩法</p>
            <salesLine :option="sstjOption" :salesCanvasSize="GameCanvasSize" :loading="sstjloadingType" @showssSalesMask="showssSalesMask" :DataType="sstjDataType"></salesLine>
            <!-- 
            <div class="check-all-wrap">
                <moduleTitle :title="salesRankTit"></moduleTitle>
                <div class="check-all-btn" @click="showRankFlag = true" v-show="salesRankList.length > 0">查看全部 ></div>
            </div>
            <div class="sale-rank-wrap">
                <ul>
                    <li class="sale-rank-item text-center" v-show="salesRankList.length == 0 && !getRankType">暂无数据！</li>
                    <li class="sale-rank-item text-center" v-show="getRankType">正在查询中...</li>
                    <li class="sale-rank-item" v-for="(v,i) in salesRankList" :key="i" v-if="i <= 9">
                        <p v-text="(selectSalesType == 2 && checkQueryIdx == 1) ? v.drawNo + '期' : checkSaleDate(v.rpt_date,1) "></p>
                        <p v-text="(selectSalesType == 2 && checkQueryIdx == 2) ? numFormat(v.value) : numFormat(v.sale) "></p>
                    </li>
                </ul>
            </div>
            -->
            <div class="daily-detail" v-show="showRankFlag">
                <div class="all-rank">
                    <h2 v-text="$options.filters.showRankDate(dateObj.date)"></h2>
                    <ul class="scrollBox">
                        <li class="sale-rank-item" v-for="(v,i) in salesRankList" :key="i">
                            <p v-text="(selectSalesType == 2 && checkQueryIdx == 1) ? v.drawNo + '期' : checkSaleDate(v.rpt_date,1) "></p>
                            <p v-text="(selectSalesType == 2 && checkQueryIdx == 2) ? numFormat(v.value) : numFormat(v.sale) "></p>
                        </li>
                    </ul>
                    <div class="close-mask" @click="showRankFlag = false">
                        <img src="../assets/imgs/close.png" alt="">
                    </div>
                </div>
            </div>
            <div class="daily-detail" v-show="showSalesFlag">
                <div class="all-rank sale-detail">
                    <h2>{{showSalesDate}}</h2>
                    <div class="scrollBox">
                        <ul class="game-list">
                            <li class="sale-rank-item" v-for="(v,i) in gameList" :key="i">
                                <p v-text="v.game_name"></p>
                                <p v-text="numFormat(v.sale)"></p>
                            </li>
                        </ul>
                        <ul class="area-list">
                            <li class="sale-rank-item" v-for="(v,i) in areaList" :key="i">
                                <p v-text="$options.filters.matchRdcId(v.rdc_id)"></p>
                                <p v-text="numFormat(v.sale)"></p>
                            </li>
                        </ul>
                    </div>
                    <div class="close-mask" @click="showSalesFlag = false">
                        <img src="../assets/imgs/close.png" alt="">
                    </div>
                </div>
            </div>
            <div class="daily-detail" v-show="showssSalesFlag">
                <div class="all-rank sale-detail" style="height:60%;transform:translate(-17.7rem, -20rem);-webkit-transform:translate(-17.7rem, -20rem);">
                    <h2>{{ssDetailName}}销量统计</h2>
                    <div class="scrollBox">
                        <salesLine :option="ssDetailOption" :loading="ssDetailloadingType"
                       :DataType="ssDetailDataType"
                       :salesCanvasSize="ssDetailCanvasSize"></salesLine>
                    </div>
                    <div class="close-mask" @click="showssSalesFlag = false">
                        <img src="../assets/imgs/close.png" alt="">
                    </div>
                </div>
            </div>
            <moduleTitle :title="publicTit" v-if="!selectSalesType"></moduleTitle>
            <ul class="publicMoney" v-if="selectSalesType != 1">
                <li class="left publicTit-item">{{publicTitItem}}</li>
                <li class="left publicMoney-item">{{publicWelfareFund}}</li>
            </ul>
        </div>
    </div>
</template>

<script>
    import salesLine from "@/components/salesLine.vue";
    import moduleTitle from "@/components/moduleTitle";
	import {MessageBox} from 'mint-ui';
	import echarts from "echarts";

    export default {
        name: "daily",
        components: {
            salesLine,
            moduleTitle
        },
        data () {
            let that = this;
            return {
                publicTitItem: '本月已筹集公益金',
                publicTit: '公益金',
                allLocSales: 0,
                token: this.getCookie('token') || '',
                salesTit: "每日销量",
                areaTit: "地区统计",
                gameTit: "玩法统计",
                salesRankTit: "销量排行",
                ggtjTit: "过关方式统计",
                sstjTit: "赛事统计",
                publicWelfareFund: '-',
                transNum: 'translateX(0rem)',
                currentTransIndex: 1,
                unitArr: ['元', '张', '元'],
                selectList: [
                    {id: 1, val: "月统计"},
                    {id: 2, val: "年统计"},
                    {id: 0, val: "在售奖期统计"}
				],
				selectListIdx: 1,
				checkQueryIdx: 1,
				ggtjList: [
					{id: 200, val: "胜平负"},
                    {id: 250, val: "比分"},
					{id: 230, val: "总进球"},
					{id: 240, val: "半全场"},
                    {id: 270, val: "胜负"},
                    {id: 210, val: "上下盘"},
                    {id: 260, val: "下半场"}
				],
				ggtjIdx: 200,
                dateObj: {
                    date: this.getNowFormatDate(2).showDate,
                    paramsDate: this.getNowFormatDate(2).paramsDate
                },
                pickerVisible: new Date(),
                selectAreaType: 1,
                selectSalesType: 0,
                saleAmt: '-',
                saleCnt: '-',
                drawCnt: '-',
                parmasDate: '',
				parmasDate2: '',
				ssDetailName: '',
                SalesOptin: {
                    tooltip: {
                        show: true,
                        enterable: true,
                        trigger: "axis",
                        // triggerOn: "click", //可点击
                        position: function (point, params, dom, rect, size) {
                            // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
                            // 提示框位置
                            let x = 0; // x坐标位置
                            let y = 0; // y坐标位置

                            // 当前鼠标位置
                            let pointX = point[0];
                            let pointY = point[1];

                            // 外层div大小
                            // let viewWidth = size.viewSize[0];
                            // let viewHeight = size.viewSize[1];

                            // 提示框大小
                            let boxWidth = size.contentSize[0];
                            let boxHeight = size.contentSize[1];

                            // boxWidth > pointX 说明鼠标左边放不下提示框
                            if (boxWidth > pointX) {
                                x = 5;
                            } else {
                                // 左边放的下
                                x = pointX - boxWidth;
                            }

                            // boxHeight > pointY 说明鼠标上边放不下提示框
                            if (boxHeight > pointY) {
                                y = 5;
                            } else {
                                // 上边放得下
                                y = pointY - boxHeight;
                            }

                            return [x, 20];
                        },
                        formatter: function(params, ticket, callback) {
                            let tit = (that.selectSalesType == 2 && that.checkQueryIdx == 1) ? params[0].name +'期' : that.checkSaleDate(params[0].name)
                            let res = `<p style="display: inline-block;margin: 10px 0 10px 10px">${tit}</p>`;
                            let pEl = '';
                            for (let i in params) {
                                if (that.selectSalesType == 2 && that.checkQueryIdx == 1) {
                                    that.parmasDate2 = params[i].name;
                                } else {
                                    that.parmasDate = params[i].data.rpt_date;
                                }
                                if (that.checkQueryIdx == 2) {
                                    pEl = '';
                                } else {
                                    pEl = '<p style="display: inline-block;margin: 10px 0 10px 10px">' + params[i].data.week + '</p>';
                                }
                                res +=
                                    '<p style="margin:10px 0 0 10px ">' +
                                    that.numFormat(params[i].value) +
                                    '</p> <div id="mytooltip1" style=" z-index: 2;\n' +
                                    "        width: 181px;" +
                                    "        height: 87px;" +
                                    "        position: absolute;" +
                                    "        top: 0;" +
                                    '        display: block;"></div><div style="height:0;' +
                                    "border-top:10px solid transparent;" +
                                    "border-bottom:10px solid transparent;" +
                                    "position: absolute;" +
                                    "right: 15px;" +
                                    "bottom: 20px;" +
                                    'border-left:15px solid #ffffff"></div>';
                            }
                            return res;
                        },
                        extraCssText: "width:181px;height:87px;background:rgba(0,0,0,0.6);border-radius:4px;font-size:22px;"
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: [],
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                        axisLabel: {
                            interval: 2,
                            fontSize: 22,
                            color: '#666666'
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: [{
                        type: "value",
                        min: 0,
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                        axisLabel: {
                            verticalAlign: "bottom",
                            inside: true,
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }, {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                    }],
                    grid: {
                        width: 680,
                        height: 322,
                        left: 35,
                        bottom: 60,
                    },
                    series: [
                        {
                            name: "全国",
                            data: [],
                            type: "line",
                            lineStyle: {
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#0000FF",
                                    lineStyle: {
                                        color: "#0000FF"
                                    }
                                }
                            },
                            symbolSize: 10
                        }
                    ]
                },
                locArr: [],
                AreaOptin: {
                    tooltip: {
                        trigger: 'axis',
                        extraCssText: 'font-size:24px;line-height:40px;padding:20px'
                    },
                    xAxis: {
                        type: "category",
                        data: [],
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                        axisLabel: {
                            interval: 2,
                            fontSize: 22,
                            color: '#666666'
                        },
                        axisTick: {
                            show: true
                        }
                    },
                    yAxis: [{
                        type: "value",
                        min: 0,
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                        axisLabel: {
                            verticalAlign: "bottom",
                            inside: true,
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }, {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                    }],
                    grid: {
                        width: 690,
                        height: 386,
                        left: 30,
                    },
                    series: [
                        {
                            keys: '',
                            name: "北京",
                            data: [
                                {
                                    rpt_date: "2017/11/12",
                                    week: "星期日",
                                    value: 9622856
                                }
                            ],
                            type: "line",
                            lineStyle: {
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#0968CE",
                                    lineStyle: {
                                        color: "#0968CE"
                                    }
                                }
                            },
                            symbolSize: 10
                        },
                        {
                            keys: '',
                            name: "广东",
                            data: [
                                {
                                    rpt_date: "2017/11/12",
                                    week: "星期日",
                                    value: 9622856
                                }
                            ],
                            type: "line",
                            lineStyle: {
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#54A64E",
                                    lineStyle: {
                                        color: "#54A64E"
                                    }
                                }
                            },
                            symbolSize: 10
                        },
                        {
                            keys: '',
                            name: "天津",
                            data: [
                                {
                                    rpt_date: "2017/11/12",
                                    week: "星期日",
                                    value: 9622856
                                }
                            ],
                            type: "line",
                            lineStyle: {
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#F4B61B",
                                    lineStyle: {
                                        color: "#F4B61B"
                                    }
                                }
                            },
                            symbolSize: 10
                        }
                    ]
                },
                GameOptin: {
                    tooltip: {
                        trigger: 'axis',
                        extraCssText: 'font-size:24px;line-height:40px;padding:20px'
                    },
                    legend: {
                        data: ['胜平负', '上下盘', '半全场', '总进球', '比分', '胜负', '下半场'],
                        top: 50,
                        width: 720,
                        height: 50,
                        itemWidth: 30,
                        textStyle: {
                            fontSize: 18,
                            fontweight: 'bold',
                        }
                    },
                    xAxis: {
                        type: "category",
                        data: [],
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                        axisLabel: {
                            interval: 2,
                            fontSize: 22,
                            color: '#666666'
                        },
                        axisTick: {
                            show: true
                        }
                    },
                    yAxis: [{
                        type: "value",
                        min: 0,
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                        axisLabel: {
                            verticalAlign: "bottom",
                            inside: true,
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }, {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                    }],
                    grid: {
                        width: 690,
                        height: 386,
                        left: 30,
                        bottom: 40,
                    },
                    series: [
                        {
                            name: "胜平负",
                            data: [],
                            type: "line",
                            lineStyle: {
                                width: 1,
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#4F9EFF",
                                    lineStyle: {
                                        color: "#4F9EFF"
                                    }
                                }
                            },
                            symbolSize: 10
                        },
                        {
                            name: "上下盘",
                            data: [],
                            type: "line",
                            lineStyle: {
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#F35B00",
                                    lineStyle: {
                                        color: "#F35B00"
                                    }
                                }
                            },
                            symbolSize: 10
                        },
                        {
                            name: "半全场",
                            data: [],
                            type: "line",
                            lineStyle: {
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#9A968A",
                                    lineStyle: {
                                        color: "#9A968A"
                                    }
                                }
                            },
                            symbolSize: 10
                        },
                        {
                            name: "总进球",
                            data: [],
                            type: "line",
                            lineStyle: {
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#E7C00D",
                                    lineStyle: {
                                        color: "#E7C00D"
                                    }
                                }
                            },
                            symbolSize: 10
                        },
                        {
                            name: "比分",
                            data: [],
                            type: "line",
                            lineStyle: {
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#296DB6",
                                    lineStyle: {
                                        color: "#296DB6"
                                    }
                                }
                            },
                            symbolSize: 10
                        },
                        {
                            name: "胜负",
                            data: [],
                            type: "line",
                            lineStyle: {
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#54A64E",
                                    lineStyle: {
                                        color: "#54A64E"
                                    }
                                }
                            },
                            symbolSize: 10
                        },
                        {
                            name: "下半场",
                            data: [],
                            type: "line",
                            lineStyle: {
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#ab0c2c",
                                    lineStyle: {
                                        color: "#ab0c2c"
                                    }
                                }
                            },
                            symbolSize: 10
                        },
                    ]
                },
                GameOptin2: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        extraCssText: 'font-size:24px;line-height:40px;padding:20px'
                    },
                    grid: {
                        left: '3%',
                        right: '15%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01],
                        axisLabel: {
                            show: false
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        splitLine: {
                            show: false
                        }
                    },
                    yAxis: {
                        type: 'category',
                        data: ['下半场比分', '上下盘单双数', '胜负过关', '半全场胜平负', '总进球数', '单场比分', '胜平负'],
                        axisLabel: {
                            fontSize: 22,
                            color: '#666666'
                        },
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                    },
                    series: [
                        {
                            name: '销售额',
                            type: 'bar',
                            barWidth: 30,
                            itemStyle: {
                                borderWidth: 10,
                                normal: {
                                    label: {
                                        show: true,  //柱头数字
                                        position: 'right',
                                        color: "#000000",
                                        fontSize: '24',
                                        formatter: function (a) {
                                            return that.numFormat(a.value)
                                        },
                                        textStyle: {
                                            fontSize: '24',
                                            fontFamily: '微软雅黑',
                                            color: "#000000"
                                        }
                                    },
                                    color: function (params) {
                                        return that.colorList[params.dataIndex]
                                    }
                                },
                            },
                            data: []
                        },
                    ]
                },
                colorList: [],
                cityOptin: {
                    series: [
                        {
                            name: '',
                            type: 'pie',
                            radius: ['45%', '70%'],
                            avoidLabelOverlap: false,
                            label: {
                                normal: {
                                    show: false,
                                    formatter: '{d}%',
                                    position: 'inside',
                                    color: '#000'
                                },
                                emphasis: {
                                    show: true,
                                    textStyle: {
                                        fontSize: '30',
                                        fontWeight: 'bold'
                                    }
                                }
                            },
                            labelLine: {
                                normal: {
                                    show: false
                                }
                            },
                            data: []
                        }
                    ],
                    color: []
                },
                ggtjOption: {
					color: ['#00a3e0','#ffa100','#bbffaa'],
					tooltip : {
						trigger: 'axis',
						axisPointer : {      // 坐标轴指示器，坐标轴触发有效
							type : 'shadow'  // 默认为直线，可选为：'line'|'shadow'
						},
						extraCssText: 'font-size:24px;line-height:40px;padding:20px'
                    },
                    grid: {
                        left: '22',
                        right: '22',
                        containLabel: true
                    },
                    dataZoom: [{
                        id: 'dataZoomX',
                        xAxisIndex: [0],
                        show: true,
                        type: 'slider',
                        startValue: 0,
                        endValue: 8,
                        showDetail: false,
                        height: 20,
                        backgroundColor: 'rgba(159,159,162,0.5)',
                        dataBackgroundColor: 'rgba(172,197,246,0.5)',
                        fillerColor: 'rgba(0, 84, 224, 0.6)',
                        handleColor: 'rgba(0, 84, 224, 0.8)',
                    },{
                        type: 'inside',
                        zoomOnMouseWheel: false,
                        moveOnMouseMove: true,
                        moveOnMouseWheel: true,
                    }],
					xAxis : {
                        type: "category",
                        data: [],
                    },
					yAxis : [{
						type : 'value'
					}],
					series : []
                },
                sstjOption: {
                    tooltip: {
                        show: true,
                        enterable: true,
                        trigger: "axis",
                        // triggerOn: "click", //可点击
                        position: function(point, params, dom, rect, size) {
                            // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
                            // 提示框位置
                            let x = 0; // x坐标位置
                            let y = 0; // y坐标位置

                            // 当前鼠标位置
                            let pointX = point[0];
                            let pointY = point[1];

                            // 外层div大小
                            // let viewWidth = size.viewSize[0];
                            // let viewHeight = size.viewSize[1];

                            // 提示框大小
                            let boxWidth = size.contentSize[0];
                            let boxHeight = size.contentSize[1];

                            // boxWidth > pointX 说明鼠标左边放不下提示框
                            if (boxWidth > pointX) {
                                x = 5;
                            } else {
                                // 左边放的下
                                x = pointX - boxWidth;
                            }

                            // boxHeight > pointY 说明鼠标上边放不下提示框
                            if (boxHeight > pointY) {
                                y = 5;
                            } else {
                                // 上边放得下
                                y = pointY - boxHeight;
                            }

                            return [x, 20];
                        },
                        formatter: function(params, ticket, callback) {
							let tit = params[0].name;
							that.ssDetailName = tit;
                            let res = `<p style="display: inline-block;margin: 10px 0 10px 10px">${tit}</p>`;
                            let pEl = '';
                            for (let i in params) {
                                res +=
                                    '<p style="margin:10px 0 0 10px ">' +
                                    that.numFormat(params[i].value) +
                                    '</p> <div id="mytooltip2" style=" z-index: 2;\n' +
                                    "        width: 181px;" +
                                    "        height: 87px;" +
                                    "        position: absolute;" +
                                    "        top: 0;" +
                                    '        display: block;"></div><div style="height:0;' +
                                    "border-top:10px solid transparent;" +
                                    "border-bottom:10px solid transparent;" +
                                    "position: absolute;" +
                                    "right: 15px;" +
                                    "bottom: 20px;" +
                                    'border-left:15px solid #ffffff"></div>';
                            }
                            return res;
                        },
                        extraCssText: "width:181px;height:87px;background:rgba(0,0,0,0.6);border-radius:4px;font-size:22px;"
					},
					dataZoom: [{
                        id: 'dataZoomX1',
                        xAxisIndex: [0],
                        show: true,
                        type: 'slider',
                        startValue: 0,
                        endValue: 8,
                        showDetail: false,
                        height: 20,
                        backgroundColor: 'rgba(159,159,162,0.5)',
                        dataBackgroundColor: 'rgba(172,197,246,0.5)',
                        fillerColor: 'rgba(0, 84, 224, 0.6)',
                        handleColor: 'rgba(0, 84, 224, 0.8)',
                    },{
                        type: 'inside',
                        zoomOnMouseWheel: false,
                        moveOnMouseMove: true,
                        moveOnMouseWheel: true,
                    }],
                    xAxis: {
                        type: "category",
                        data: [],
                    },
                    yAxis: [{
                        type: "value",
                    }],
                    series: [
                        {
                            name: "",
                            data: [],
                            type: "bar",
                            barWidth: 30,
                            itemStyle: {
                                borderWidth: 10,
								color: '#0069ff',
                            },
                        }
                    ]
				},
				ssDetailOption: {
                    tooltip: {
                        show: true,
                        enterable: true,
						trigger: "axis",
						extraCssText: 'font-size:24px;line-height:40px;padding:20px'
                    },
                    xAxis: {
                        type: "category",
                        boundaryGap: false,
                        data: [],
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                        axisLabel: {
                            interval: 2,
                            fontSize: 22,
                            color: '#666666'
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: [{
                        type: "value",
                        min: 0,
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                        axisLabel: {
                            verticalAlign: "bottom",
                            inside: true,
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    }, {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: "#BDBDBD"
                            }
                        },
                    }],
                    grid: {
                        width: 615,
                        height: 330,
						left: 25,
						right: 20,
                        bottom: 60,
                    },
                    series: [
                        {
                            name: "",
                            data: [],
                            type: "line",
                            lineStyle: {
                                width: 1
                            },
                            itemStyle: {
                                borderWidth: 2,
                                normal: {
                                    color: "#0000FF",
                                    lineStyle: {
                                        color: "#0000FF"
                                    }
                                }
                            },
                            symbolSize: 10
                        }
                    ]
                },
                salesCanvasSize: {
                    w: "37.5rem",
                    h: "25.8rem"
                },
                cityCanvasSize: {
                    w: "14rem",
                    h: "14rem"
                },
                AreaCanvasSize: {
                    w: "37.5rem",
                    h: "25rem"
                },
                Area2CanvasSize: {
                    w: "37.5rem",
                    h: "25rem"
                },
                GameCanvasSize: {
                    w: "37.5rem",
                    h: "27.8rem"
                },
                GameCanvasSize2: {
                    w: "37.5rem",
                    h: "27.8rem"
				},
				ssDetailCanvasSize: {
					w: "33.5rem",
                    h: "20rem"
				},
				ggtjCanvasSize: {
					w: "37.5rem",
                    h: "25.8rem"
				},
                SalesloadingType: false,
                SalesHasDataType: true,
                ArealoadingType: false,
                AreaHasDataType: true,
                CityHasDataType: true,
                GameloadingType: false,
                GameHasDataType: true,
                Game2HasDataType: true,
				ggtjloadingType: false,
				ggtjDataType: true,
                sstjloadingType: false,
				sstjDataType: true,
				ssDetailloadingType: false,
				ssDetailDataType: true,
                bjSale: '',
                gdSale: '',
                tjSale: '',
                salesRankList: [],
                showRankFlag: false,
                showSalesFlag: false,
                showssSalesFlag: false,
                gameList: [],
                areaList: [],
                hasQgPerm: false,
                hasCenter: false,
                cancelAjax1: null,
                cancelAjax2: null,
                cancelAjax3: null,
                cancelAjax4: null,
                cancelAjax5: null,
                cancelAjax6: null,
                cancelAjax7: null,
                cancelAjax8: null,
                showlocIndex: 3,
                colorArr: [],
                tips: '展开全部 >',
				getRankType: false,
				sstjResList: [],
            };
        },
        filters: {
            showRankDate (val) {
                let str = '';
                if (val.indexOf('月') > -1) {
                    return str = val.split('年')[1] + '销售排行'
                } else {
                    return str = val + '销售排行'
                }
            },
            matchRdcId (id) {
                switch (id) {
                    case 0:
                        return '全国';
                    case 10:
                        return '北京';
                    case 20:
                        return '广东';
                    case 22:
                        return '天津';
                    default:
                        return '-';
                }
            },
        },
        computed: {
            showSalesDate () {
                let val = this.parmasDate;
                let val2 = this.parmasDate2;
                let str = '';
                // if (!val) {
                //     return
                // }
                if (this.selectSalesType == 0) {
                    if (this.checkQueryIdx == 2) {
                        return str = (val.split('/')[1] + '月').replace(/^0/, '') + '销售额'
                    } else {
                        return str = (val.split('/')[1] + '月').replace(/^0/, '') + (val.split('/')[2] + '日').replace(/^0/, '') + '销售额'
                    }
                } else  if (this.selectSalesType == 1) {
                    if (this.checkQueryIdx == 2) {
                        return str = (val.split('/')[1] + '月').replace(/^0/, '') + '销售票数'
                    } else {
                        return str = (val.split('/')[1] + '月').replace(/^0/, '') + (val.split('/')[2] + '日').replace(/^0/, '') + '销售票数'
                    }
                }else  if (this.selectSalesType == 2) {
                    if (this.checkQueryIdx == 2) {
                        return str = (val.split('/')[1] + '月').replace(/^0/, '') + '销售额'
                    } else {
                        return str = val2 + '期销售额'
                    }
                }
            }
        },
        methods: {
            /**
             * HSL颜色值转换为RGB
             * H，S，L 设定在 [0, 1] 之间
             * R，G，B 返回在 [0, 255] 之间
             *
             * @param H 色相
             * @param S 饱和度
             * @param L 亮度
             * @returns Array RGB色值
             */
            hslToRgb (H, S, L) {
                var R, G, B;
                if (+S === 0) {
                    R = G = B = L; // 饱和度为0 为灰色
                } else {
                    var hue2Rgb = function (p, q, t) {
                        if (t < 0) t += 1;
                        if (t > 1) t -= 1;
                        if (t < 1 / 6) return p + (q - p) * 6 * t;
                        if (t < 1 / 2) return q;
                        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                        return p;
                    };
                    var Q = L < 0.5 ? L * (1 + S) : L + S - L * S;
                    var P = 2 * L - Q;
                    R = hue2Rgb(P, Q, H + 1 / 3);
                    G = hue2Rgb(P, Q, H);
                    B = hue2Rgb(P, Q, H - 1 / 3);
                }
                return [Math.round(R * 255), Math.round(G * 255), Math.round(B * 255)];
            },
            // 获取随机HSL
            randomHsl () {
                var H = Math.random();
                var S = Math.random();
                var L = Math.random();
                return [H, S, L];
            },
            // 获取HSL数组
            getHslArray (cnt) {
                var HSL = [];
                var hslLength = cnt; // 获取数量
                for (var i = 0; i < hslLength; i++) {
                    var ret = this.randomHsl();

                    // 颜色相邻颜色差异须大于 0.25
                    if (i > 0 && Math.abs(ret[0] - HSL[i - 1][0]) < 0.25) {
                        i--;
                        continue; // 重新获取随机色
                    }
                    ret[1] = 0.7 + (ret[1] * 0.2); // [0.7 - 0.9] 排除过灰颜色
                    ret[2] = 0.4 + (ret[2] * 0.4); // [0.4 - 0.8] 排除过亮过暗色

                    // 数据转化到小数点后两位
                    ret = ret.map(function (item) {
                        return parseFloat(item.toFixed(2));
                    });

                    HSL.push(ret);
                }
                return HSL;
            },
            getPercent (num, total) {
                return (Math.round(num / total * 10000) / 100.00) >= 0 ? Math.round(num / total * 10000) / 100.00 + "%" : 0;// 小数点后两位百分比
            },
            showAllLoc () {
                if (this.showlocIndex == this.locArr.length) {
                    this.showlocIndex = 3;
                    this.tips = '展开全部 >'
                } else {
                    this.showlocIndex = this.locArr.length;
                    this.tips = '收起'
                }
            },
            showSalesMask () {
                this.showSalesFlag = true;
                let timeStr = this.parmasDate;
                let drawNo = this.parmasDate2;
                this.gameList = [];
                this.areaList = [];
                this.checkQueryIdx == 2 ? timeStr = (this.parmasDate.split('/')[0] + '/' + this.parmasDate.split('/')[1]) : timeStr = this.parmasDate;
                if(this.selectSalesType == 2){
                    this.$axios({
                        method: "post",
                        url: "web/GetDrawSaleDetail",
                        data: {
                            token: this.token,
                            type: this.checkQueryIdx - 1,
                            timeStr: timeStr,
                            draw_no: drawNo,
                            area: this.selectAreaType,
                        }
                    }).then(res => {
                        let _data = res.data;
                        if (_data.code == 0) {
                            this.gameList = _data.gameList;
                            this.areaList = _data.areaList;
                        } else if (_data.code == -200) {
                            MessageBox.alert(_data.message).then(action => {
                                this.delCookie('token')
                            });
                        } else {
                            MessageBox('提示', _data.message);
                        }
                    }).catch(err => {
                        MessageBox('提示', '网络异常');
                    });
                }else{
                    this.$axios({
                        method: "post",
                        url: "web/GetSaleDetail",
                        data: {
                            token: this.token,
                            timeType: this.checkQueryIdx,
                            timeStr: timeStr,
                            area: this.selectAreaType,
                            saleType: this.selectSalesType
                        }
                    }).then(res => {
                        let _data = res.data;
                        if (_data.code == 0) {
                            this.gameList = _data.gameList;
                            this.areaList = _data.areaList;
                        } else if (_data.code == -200) {
                            MessageBox.alert(_data.message).then(action => {
                                this.delCookie('token')
                            });
                        } else {
                            MessageBox('提示', _data.message);
                        }
                    }).catch(err => {
                        MessageBox('提示', '网络异常');
                    });
                }
            },
            showssSalesMask() {
				this.showssSalesFlag = true;
				let that = this;
				let timeStr = '';
                this.checkQueryIdx == 0 ? timeStr = null : timeStr = this.dateObj.paramsDate.toString();
				this.ssDetailloadingType = true;
				this.ssDetailDataType = false;
				this.ssDetailOption.xAxis.data = [];
                this.ssDetailOption.series[0].data = [];
				this.ssDetailOption.series[0].name = this.ssDetailName;
				const CancelToken = this.$axios.CancelToken;
				if (typeof this.cancelAjax7 === 'function') {
                    this.cancelAjax7()
                }
				this.$axios({
                        method: "post",
                        url: "web/GetLeagueSaleDetail",
                        data: {
                            token: this.token,
                            dateType: this.checkQueryIdx,
                            timeStr: timeStr,
                            league_id: this.getLeagueId(this.ssDetailName),
                        },
                        cancelToken: new CancelToken(function executor (c) {
                            that.cancelAjax7 = c
                        })
                    }).then(res => {
                        this.ssDetailloadingType = false;
                        let _data = res.data;
                        if (_data.code == 0) {
                            for (let i in _data.list) {
                                this.ssDetailOption.xAxis.data.push(this.changeVal(_data.list[i].time));
                                this.ssDetailOption.series[0].data.push(_data.list[i].sale_amt);
                            }
                            if (this.ssDetailOption.series[0].data.length == 0) {
                                this.ssDetailDataType = true;
                            }
                        } else if (_data.code == -200) {
                            MessageBox.alert(_data.message).then(action => {
                                this.delCookie('token')
                            });
                        } else {
                            MessageBox('提示', _data.message);
                        }
                    }).catch(err => {
                        if (!this.$axios.isCancel(err)) {
                            this.ssDetailloadingType = false;
                            MessageBox('提示', '网络异常');
                        }
                    });
            },
            // 选择查询类型
            checkQueryType (idx) {
				this.selectListIdx = idx;
                if (this.checkQueryIdx != idx) {
                    if(this.selectSalesType == 2 && idx == 0){
                        this.selectSalesType = 0;
                    }
                    this.checkQueryIdx = idx;
                    if (idx == 0) {
                        this.salesTit = '在售奖期销量'
                        this.publicTitItem = '本奖期已筹集公益金'
                    } else if (idx == 1) {
                        if (this.selectSalesType == 2) {
                            this.salesTit = '每期销量'
                        } else {
                            this.salesTit = '每日销量'
                        }
                        this.publicTitItem = '本月已筹集公益金'

                    } else {
                        this.salesTit = '每月销量'
                        this.publicTitItem = '本年度已筹集公益金'
                    }
                    this.setShowDateByType(idx);
                    this.setDateByType(idx);
                    this.getSaleAmtAndCnt();
                    this.getSales();
                    this.getAreaSales();
                    this.getGameSales();
                    // this.getSalesRank();
					this.getsstj();
					this.getggtj();
                }
			},
			// 选择过关统计类型
			checkggtjType(idx) {
				if (this.ggtjIdx != idx) {
					this.ggtjIdx = idx;
					this.getggtj();
				}
			},
            // 设置时间格式
            setDateByType (idx) {
                if (idx == 1) {
                    this.dateObj.date = this.getNowFormatDate(2).showDate;
                    this.dateObj.paramsDate = this.getNowFormatDate(2).paramsDate;
                } else if (idx == 2) {
                    this.dateObj.date = this.getNowFormatDate(3).showDate;
                    this.dateObj.paramsDate = this.getNowFormatDate(3).paramsDate;
                }
            },
            //点击时间插件时对插件样式的操作
            setShowDateByType (idx) {
                if (idx == 1) {
                    this.$refs.picker.$el.getElementsByClassName(
                        "picker-slot"
                    )[0].style.display = "block";
                    this.$refs.picker.$el.getElementsByClassName(
                        "picker-slot"
                    )[1].style.display = "block";
                    this.$refs.picker.$el.getElementsByClassName(
                        "picker-slot"
                    )[2].style.display = "none";
                } else if (idx == 2) {
                    this.$refs.picker.$el.getElementsByClassName(
                        "picker-slot"
                    )[0].style.display = "block";
                    this.$refs.picker.$el.getElementsByClassName(
                        "picker-slot"
                    )[1].style.display = "none";
                    this.$refs.picker.$el.getElementsByClassName(
                        "picker-slot"
                    )[2].style.display = "none";
                }
            },
            openPicker () {
                this.$refs.picker.open();
                this.setShowDateByType(this.checkQueryIdx);
            },
            // 全国中心
            setActiveBySelected (type) {
                if (type != this.selectAreaType) {
                    this.selectAreaType = type;
                    this.getSaleAmtAndCnt();
                    this.getSales();
                    this.getAreaSales();
                    this.getGameSales();
                    // this.getSalesRank();
					this.getsstj();
					this.getggtj();
                }
            },
            // 销售额 / 期销售额 / 票数
            setClassNameBySelectSales (type) {
                if (type != this.selectSalesType) {
                    this.selectSalesType = type;
                    if (type == 2) {
                        this.salesTit = '每期销量'
                        this.getSaleAmtAndCnt();
                        this.getSales();
                        this.getAreaSales();
                        this.getGameSales();
                        // this.getSalesRank();
						this.getsstj();
						this.getggtj();
                    } else {
						if(this.selectListIdx == 1) {
							this.salesTit = '每日销量'
						} else if(this.selectListIdx == 2) {
							this.salesTit = '每月销量'
						} else {
							this.salesTit = '在售奖期销量'
						}
                        if (type == 0) {
                            this.GameOptin2.series[0].name = '销售额';
                        } else if (type == 1) {
                            this.GameOptin2.series[0].name = '销售票数';
                        }
                        this.getSaleAmtAndCnt();
                        this.getSales();
                        this.getAreaSales();
                        this.getGameSales();
                        // this.getSalesRank();
						this.getsstj();
						this.getggtj();
                    }
                }
            },
            // 格式化获取的时间
            formatDate (date, type, showType = 0) {
                const y = date.getFullYear();
                let m = date.getMonth() + 1;
                m = m < 10 ? "0" + m : m;
                let m2 = date.getMonth() + 1;
                // m2 = m2 < 10 ? "0" + m2 : m2;
                let d = date.getDate();
                d = d < 10 ? "0" + d : d;
                if (showType == 0) {
                    if (type == 1) {
                        return y + "年" + m2 + "月";
                    } else if (type == 2) {
                        return y + "年";
                    }
                } else {
                    if (type == 1) {
                        return y + "/" + m;
                    } else if (type == 2) {
                        return y;
                    }
                }
            },
            //获取当前时间
            getNowFormatDate (type) {
                let date = new Date();
                let seperator1 = "/";
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let month2 = date.getMonth() + 1;
                let strDate = date.getDate();
                if (month >= 1 && month <= 9) {
                    month = "0" + month;
                }
                let currentDate = {
                    showDate: "",
                    paramsDate: ""
                };
                if (strDate >= 0 && strDate <= 9) {
                    strDate = "0" + strDate;
                }
                if (type == 1) {
                    currentDate.showDate =
                        year + "年" + month2 + "月" + strDate + "日";
                    currentDate.paramsDate = year + "/" + month + "/" + strDate;
                } else if (type == 2) {
                    currentDate.showDate = year + "年" + month2 + "月";
                    currentDate.paramsDate = year + "/" + month;
                } else if (type == 3) {
                    currentDate.showDate = year + "年";
                    currentDate.paramsDate = year;
                }
                return currentDate;
            },
            //点击确定按钮之后
            handleConfirm () {
                // 输出格式化后的时间
                if (this.checkQueryIdx == 1) {
                    this.dateObj.date = this.formatDate(this.$refs.picker.value, 1);
                    this.dateObj.paramsDate = this.formatDate(this.$refs.picker.value, 1, 1);
                } else if (this.checkQueryIdx == 2) {
                    this.dateObj.date = this.formatDate(this.$refs.picker.value, 2);
                    this.dateObj.paramsDate = this.formatDate(this.$refs.picker.value, 2, 1);
                }
                this.getSaleAmtAndCnt();
                this.getSales();
                this.getAreaSales();
                this.getGameSales();
                // this.getSalesRank();
				this.getsstj();
				this.getggtj();
            },
            // 查询销售量和销售票数 GetSaleAmtAndCnt
            getSaleAmtAndCnt () {
                let that = this;
                let timeStr = '';
                this.saleAmt = '-';
                this.saleCnt = '-';
                this.drawCnt = '-';
                this.publicWelfareFund = '-'
                this.checkQueryIdx == 0 ? timeStr = null : timeStr = this.dateObj.paramsDate.toString();
                const CancelToken = this.$axios.CancelToken;
                if (typeof this.cancelAjax1 === 'function') {
                    this.cancelAjax1()
                }
                this.$axios({
                    method: "post",
                    url: "web/GetSaleAmtAndCnt",
                    data: {
                        token: this.token,
                        dateType: this.checkQueryIdx,
                        timeStr: timeStr,
                        area: this.selectAreaType
                    },
                    cancelToken: new CancelToken(function executor (c) {
                        that.cancelAjax1 = c
                    })
                }).then(res => {
                    let _data = res.data;
                    if (_data.code == 0) {
                        this.saleAmt = this.numFormat(_data.sale_amt);
                        this.saleCnt = this.numFormat(_data.sale_cnt);
                        this.drawCnt = this.numFormat(_data.drawSale);
                        if (this.selectSalesType == 2) {
                            this.publicWelfareFund = this.numFormat(_data.drawPublicWelfareFund) + '元';
                        } else {
                            this.publicWelfareFund = this.numFormat(_data.publicWelfareFund) + '元';
                        }
                    } else if (_data.code == -200) {
                        MessageBox.alert(_data.message).then(action => {
                            this.delCookie('token')
                        });
                    } else {
                        MessageBox('提示', _data.message);
                    }
                }).catch(err => {
                    if (!this.$axios.isCancel(err)) {
                        MessageBox('提示', '网络异常');
                    }
                });
            },
            //查询销量 GetDaySale
            getSales () {
                const CancelToken = this.$axios.CancelToken;
                if (typeof this.cancelAjax2 === 'function') {
                    this.cancelAjax2()
                }
                let timeStr = '';
                this.checkQueryIdx == 0 ? timeStr = null : timeStr = this.dateObj.paramsDate.toString();
                this.SalesOptin.xAxis.data = [];
                this.SalesOptin.series[0].data = [];
                let that = this;
                this.SalesloadingType = true;
                this.SalesHasDataType = false;
                if (this.selectSalesType == 2) {
                    this.$axios({
                        method: "post",
                        url: "web/GetDrawDaySale",
                        data: {
                            token: this.token,
                            dateType: this.checkQueryIdx,
                            timeStr: timeStr,
                            area: this.selectAreaType,
                        },
                        cancelToken: new CancelToken(function executor (c) {
                            that.cancelAjax2 = c
                        })
                    }).then(res => {
                        this.SalesloadingType = false;
                        let _data = res.data;
                        if (_data.code == 0) {
                            if (this.checkQueryIdx == 1) {
                                for (let i in _data.drawSaleList) {
                                    this.SalesOptin.xAxis.data.push(_data.drawSaleList[i].drawNo);
                                    this.SalesOptin.series[0].data.push(_data.drawSaleList[i].sale);
                                }
                            } else {
                                for (let i in _data.daySaleList) {
                                    this.SalesOptin.xAxis.data.push(this.changeVal(_data.daySaleList[i].rpt_date));
                                    this.SalesOptin.series[0].data.push(_data.daySaleList[i]);
                                }
                            }

                            if (this.SalesOptin.series[0].data.length == 0) {
                                this.SalesHasDataType = true;
                            }
                        } else if (_data.code == -200) {
                            MessageBox.alert(_data.message).then(action => {
                                this.delCookie('token')
                            });
                        } else {
                            MessageBox('提示', _data.message);
                        }
                    }).catch(err => {
                        if (!this.$axios.isCancel(err)) {
                            this.SalesloadingType = false;
                            MessageBox('提示', '网络异常');
                        }
                    });
                } else {
                    this.$axios({
                        method: "post",
                        url: "web/GetDaySale",
                        data: {
                            token: this.token,
                            dateType: this.checkQueryIdx,
                            timeStr: timeStr,
                            area: this.selectAreaType,
                            type: this.selectSalesType
                        },
                        cancelToken: new CancelToken(function executor (c) {
                            that.cancelAjax2 = c
                        })
                    }).then(res => {
                        this.SalesloadingType = false;
                        let _data = res.data;
                        if (_data.code == 0) {
                            for (let i in _data.list) {
                                this.SalesOptin.xAxis.data.push(this.changeVal(_data.list[i].rpt_date));
                                this.SalesOptin.series[0].data.push(_data.list[i]);
                            }
                            if (this.SalesOptin.series[0].data.length == 0) {
                                this.SalesHasDataType = true;
                            }
                        } else if (_data.code == -200) {
                            MessageBox.alert(_data.message).then(action => {
                                this.delCookie('token')
                            });
                        } else {
                            MessageBox('提示', _data.message);
                        }
                    }).catch(err => {
                        if (!this.$axios.isCancel(err)) {
                            this.SalesloadingType = false;
                            MessageBox('提示', '网络异常');
                        }
                    });
                }

            },
            //查询地区销量 GetLocalSale
            getAreaSales () {
                const CancelToken = this.$axios.CancelToken;
                if (typeof this.cancelAjax3 === 'function') {
                    this.cancelAjax3()
                }
                let that = this;
                let timeStr = '';
                this.checkQueryIdx == 0 ? timeStr = null : timeStr = this.dateObj.paramsDate.toString();
                this.AreaOptin.xAxis.data = [];
                this.AreaOptin.series[0].data = [];
                this.AreaOptin.series[1].data = [];
                this.AreaOptin.series[2].data = [];
                this.cityOptin.series[0].data = [];
                this.AreaOptin.series[0].keys = '';
                this.AreaOptin.series[1].keys = '';
                this.AreaOptin.series[2].keys = '';
                this.allLocSales = 0;
                this.locArr = [];
                this.ArealoadingType = true;
                this.AreaHasDataType = false;
                this.CityHasDataType = false;
                if (this.selectSalesType == 2) {
                    this.$axios({
                        method: "post",
                        url: "web/GetDrawLocalSale",
                        data: {
                            token: this.token,
                            dateType: this.checkQueryIdx,
                            timeStr: timeStr,
                            area: this.selectAreaType,
                        },
                        cancelToken: new CancelToken(function executor (c) {
                            that.cancelAjax3 = c
                        })
                    }).then(res => {
                        this.ArealoadingType = false;
                        let _data = res.data;
                        if (_data.code == 0) {
                            let arr = this.getHslArray(_data.dicList.length);
                            let rgbArray = this.getHslArray(_data.dicList.length).map((item) => {
                                return this.hslToRgb.apply(this, item);
                            });
                            let colorArr = rgbArray.map((item) => {
                                return 'rgb(' + item.toString() + ')'

                            });
                            if (this.checkQueryIdx == 1) {
                                for (let i in _data.queryMonthList) {
                                    this.AreaOptin.xAxis.data.push(_data.queryMonthList[i].draw_no);
                                    if (_data.queryMonthList[i].list[0]) {
                                        this.AreaOptin.series[0].data.push(_data.queryMonthList[i].list[0].value);
                                        this.AreaOptin.series[0].name = _data.queryMonthList[i].list[0].name;
                                        this.AreaOptin.series[0].keys = _data.queryMonthList[i].list[0].id;
                                    }
                                    if (_data.queryMonthList[i].list[1]) {
                                        this.AreaOptin.series[1].data.push(_data.queryMonthList[i].list[1].value);
                                        this.AreaOptin.series[1].name = _data.queryMonthList[i].list[1].name;
                                        this.AreaOptin.series[1].keys = _data.queryMonthList[i].list[1].id;
                                    }
                                    if (_data.queryMonthList[i].list[2]) {
                                        this.AreaOptin.series[2].data.push(_data.queryMonthList[i].list[2].value);
                                        this.AreaOptin.series[2].name = _data.queryMonthList[i].list[2].name;
                                        this.AreaOptin.series[2].keys = _data.queryMonthList[i].list[2].id;
                                    }
                                }
                            } else {
                                for (let i in _data.queryYearList) {
                                    this.AreaOptin.xAxis.data.push(this.changeVal(_data.queryYearList[i].rpt_date));
                                    if (_data.queryYearList[i].list[0]) {
                                        this.AreaOptin.series[0].data.push(_data.queryYearList[i].list[0].value);
                                        this.AreaOptin.series[0].name = _data.queryYearList[i].list[0].name;
                                        this.AreaOptin.series[0].keys = _data.queryYearList[i].list[0].id;
                                    }
                                    if (_data.queryYearList[i].list[1]) {
                                        this.AreaOptin.series[1].data.push(_data.queryYearList[i].list[1].value);
                                        this.AreaOptin.series[1].name = _data.queryYearList[i].list[1].name;
                                        this.AreaOptin.series[1].keys = _data.queryYearList[i].list[1].id;
                                    }
                                    if (_data.queryYearList[i].list[2]) {
                                        this.AreaOptin.series[2].data.push(_data.queryYearList[i].list[2].value);
                                        this.AreaOptin.series[2].name = _data.queryYearList[i].list[2].name;
                                        this.AreaOptin.series[2].keys = _data.queryYearList[i].list[2].id;
                                    }
                                }
                            }

                            for (let i in this.AreaOptin.series) {
                                if (_data.dicList[0] && this.AreaOptin.series[i].keys == _data.dicList[0].id) {
                                    this.AreaOptin.series[i].itemStyle.normal.color = colorArr[0] || '#0968CE';
                                    this.AreaOptin.series[i].itemStyle.normal.lineStyle.color = colorArr[0] || '#0968CE';
                                } else if (_data.dicList[1] && this.AreaOptin.series[i].keys == _data.dicList[1].id) {
                                    this.AreaOptin.series[i].itemStyle.normal.color = colorArr[1] || '#54A64E';
                                    this.AreaOptin.series[i].itemStyle.normal.lineStyle.color = colorArr[1] || '#54A64E';
                                } else if (_data.dicList[2] && this.AreaOptin.series[i].keys == _data.dicList[2].id) {
                                    this.AreaOptin.series[i].itemStyle.normal.color = colorArr[2] || '#F4B61B';
                                    this.AreaOptin.series[i].itemStyle.normal.lineStyle.color = colorArr[2] || '#F4B61B';
                                }
                            }
                            if (this.AreaOptin.series[0].data.length == 0 && this.AreaOptin.series[1].data.length == 0 && this.AreaOptin.series[2].data.length == 0) {
                                this.AreaHasDataType = true;
                            }
                            for (let i in _data.dicList) {
                                this.allLocSales += parseInt(_data.dicList[i].value);
                                this.locArr.push({
                                    cnt: _data.dicList[i].value,
                                    name: _data.dicList[i].name
                                })
                                this.cityOptin.series[0].data.push({
                                    value: _data.dicList[i].value,
                                    name: _data.dicList[i].name
                                })
                            }
                            if (this.cityOptin.series[0].data.length == 0) {
                                this.CityHasDataType = true;
                            }
                            this.cityOptin.color = colorArr;
                            this.colorArr = colorArr;
                        } else if (_data.code == -200) {
                            MessageBox.alert(_data.message).then(action => {
                                this.delCookie('token')
                            });
                        } else {
                            MessageBox('提示', _data.message);
                        }
                    }).catch(err => {
                        if (!this.$axios.isCancel(err)) {
                            this.ArealoadingType = false;
                            MessageBox('提示', '网络异常');
                        }
                    });
                } else {
                    this.$axios({
                        method: "post",
                        url: "web/GetLocalSale",
                        data: {
                            token: this.token,
                            dateType: this.checkQueryIdx,
                            timeStr: timeStr,
                            area: this.selectAreaType,
                            type: this.selectSalesType
                        },
                        cancelToken: new CancelToken(function executor (c) {
                            that.cancelAjax3 = c
                        })
                    }).then(res => {
                        this.ArealoadingType = false;
                        let _data = res.data;
                        if (_data.code == 0) {
                            let arr = this.getHslArray(_data.dicList.length);
                            let rgbArray = this.getHslArray(_data.dicList.length).map((item) => {
                                return this.hslToRgb.apply(this, item);
                            });
                            let colorArr = rgbArray.map((item) => {
                                return 'rgb(' + item.toString() + ')'

                            });
                            for (let i in _data.threeList) {
                                this.AreaOptin.xAxis.data.push(this.changeVal(_data.threeList[i].rpt_date));
                                if (_data.threeList[i].list[0]) {
                                    this.AreaOptin.series[0].data.push(_data.threeList[i].list[0].value);
                                    this.AreaOptin.series[0].name = _data.threeList[i].list[0].name;
                                    this.AreaOptin.series[0].keys = _data.threeList[i].list[0].id;
                                }
                                if (_data.threeList[i].list[1]) {
                                    this.AreaOptin.series[1].data.push(_data.threeList[i].list[1].value);
                                    this.AreaOptin.series[1].name = _data.threeList[i].list[1].name;
                                    this.AreaOptin.series[1].keys = _data.threeList[i].list[1].id;
                                }
                                if (_data.threeList[i].list[2]) {
                                    this.AreaOptin.series[2].data.push(_data.threeList[i].list[2].value);
                                    this.AreaOptin.series[2].name = _data.threeList[i].list[2].name;
                                    this.AreaOptin.series[2].keys = _data.threeList[i].list[2].id;
                                }
                            }
                            for (let i in this.AreaOptin.series) {
                                if (_data.dicList[0] && this.AreaOptin.series[i].keys == _data.dicList[0].id) {
                                    this.AreaOptin.series[i].itemStyle.normal.color = colorArr[0] || '#0968CE';
                                    this.AreaOptin.series[i].itemStyle.normal.lineStyle.color = colorArr[0] || '#0968CE';
                                } else if (_data.dicList[1] && this.AreaOptin.series[i].keys == _data.dicList[1].id) {
                                    this.AreaOptin.series[i].itemStyle.normal.color = colorArr[1] || '#54A64E';
                                    this.AreaOptin.series[i].itemStyle.normal.lineStyle.color = colorArr[1] || '#54A64E';
                                } else if (_data.dicList[2] && this.AreaOptin.series[i].keys == _data.dicList[2].id) {
                                    this.AreaOptin.series[i].itemStyle.normal.color = colorArr[2] || '#F4B61B';
                                    this.AreaOptin.series[i].itemStyle.normal.lineStyle.color = colorArr[2] || '#F4B61B';
                                }
                            }
                            if (this.AreaOptin.series[0].data.length == 0 && this.AreaOptin.series[1].data.length == 0 && this.AreaOptin.series[2].data.length == 0) {
                                this.AreaHasDataType = true;
                            }
                            for (let i in _data.dicList) {
                                this.allLocSales += parseInt(_data.dicList[i].value);
                                this.locArr.push({
                                    cnt: _data.dicList[i].value,
                                    name: _data.dicList[i].name
                                })
                                this.cityOptin.series[0].data.push({
                                    value: _data.dicList[i].value,
                                    name: _data.dicList[i].name
                                })
                            }
                            if (this.cityOptin.series[0].data.length == 0) {
                                this.CityHasDataType = true;
                            }
                            this.cityOptin.color = colorArr;
                            this.colorArr = colorArr;
                        } else if (_data.code == -200) {
                            MessageBox.alert(_data.message).then(action => {
                                this.delCookie('token')
                            });
                        } else {
                            MessageBox('提示', _data.message);
                        }
                    }).catch(err => {
                        if (!this.$axios.isCancel(err)) {
                            this.ArealoadingType = false;
                            MessageBox('提示', '网络异常');
                        }
                    });
                }

            },
            //查询销量排行 GetRankList
            getSalesRank () {
                let timeStr = '';
                this.checkQueryIdx == 0 ? timeStr = null : timeStr = this.dateObj.paramsDate.toString();
                let that = this;
                const CancelToken = this.$axios.CancelToken;
                if (typeof this.cancelAjax4 === 'function') {
                    this.cancelAjax4()
                }
                this.getRankType = true;
                if (this.selectSalesType == 2) {
                    this.$axios({
                        method: "post",
                        url: "web/GetDrawRankList",
                        data: {
                            token: this.token,
                            dateType: this.checkQueryIdx,
                            timeStr: timeStr,
                            area: this.selectAreaType,
                        },
                        cancelToken: new CancelToken(function executor (c) {
                            that.cancelAjax4 = c
                        })
                    }).then(res => {
                        this.getRankType = false;
                        let _data = res.data;
                        if (_data.code == 0) {
                            if (this.checkQueryIdx == 1) {
                                this.salesRankList = _data.drawSaleList;
                            } else {
                                this.salesRankList = _data.daySaleList;
                            }
                        } else if (_data.code == -200) {
                            MessageBox.alert(_data.message).then(action => {
                                this.delCookie('token')
                            });
                        } else {
                            MessageBox('提示', _data.message);
                        }
                    }).catch(err => {
                        if (!this.$axios.isCancel(err)) {
                            this.getRankType = false;
                            MessageBox('提示', '网络异常');
                        }
                    });
                } else {
                    this.$axios({
                        method: "post",
                        url: "web/GetRankList",
                        data: {
                            token: this.token,
                            dateType: this.checkQueryIdx,
                            timeStr: timeStr,
                            area: this.selectAreaType,
                            type: this.selectSalesType
                        },
                        cancelToken: new CancelToken(function executor (c) {
                            that.cancelAjax4 = c
                        })
                    }).then(res => {
                        this.getRankType = false;
                        let _data = res.data;
                        if (_data.code == 0) {
                            this.salesRankList = _data.list;
                        } else if (_data.code == -200) {
                            MessageBox.alert(_data.message).then(action => {
                                this.delCookie('token')
                            });
                        } else {
                            MessageBox('提示', _data.message);
                        }
                    }).catch(err => {
                        if (!this.$axios.isCancel(err)) {
                            this.getRankType = false;
                            MessageBox('提示', '网络异常');
                        }
                    });
                }
			},
			getggtj() {
                const CancelToken = this.$axios.CancelToken;
                if (typeof this.cancelAjax8 === 'function') {
                    this.cancelAjax8()
				}
				let timeStr = '';
				this.checkQueryIdx == 0 ? timeStr = null : timeStr = this.dateObj.paramsDate.toString();
                let that = this;
				this.ggtjloadingType = true;
				this.ggtjDataType = false;
				this.ggtjOption.xAxis.data = [];
				this.ggtjOption.series = [];
				this.ggtjOption.tooltip.formatter = function(params){
					let relVal = timeStr? '<p style="text-align:center;">'+timeStr + '</p>' : '';
					relVal += params[0].name;
					for(let i = 0; i < params.length; i ++) {
						relVal += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value + '元';
					}
					return relVal;
				
				};
				this.$axios({
					method: "post",
					url: "web/GetMxnSale",
					data: {
						token: this.token,
						dateType: this.checkQueryIdx,
						timeStr: timeStr,
						area: this.selectAreaType,
						game_id: this.ggtjIdx
					},
					cancelToken: new CancelToken(function executor (c) {
						that.cancelAjax8 = c
					})
				}).then(res => {
					this.ggtjloadingType = false;
                    let _data = res.data;
					if (_data.code == 0) {
						let _listLen = _data.list.length;
						if(_listLen > 9){
							this.ggtjCanvasSize.w = (750 + (_listLen - 9) * 70);
						} else {
							this.ggtjCanvasSize.w = 750;
						}
						if(this.selectAreaType == 0) {
							this.ggtjOption.series = [{
								name:'北京',
								type:'bar',
								barWidth: 30,
								stack: 'statistics',
								data:[]
							},{
								name:'天津',
								type:'bar',
								stack: 'statistics',
								data:[]
							},{
								name:'广东',
								stack: 'statistics',
								type:'bar',
								data:[]
							}];
						} else {
							this.ggtjOption.series = [{
								name:'',
								type:'bar',
								barWidth: 30,
								data:[]
							}];
						}
						for (let i in _data.list) {
							this.ggtjOption.xAxis.data.push(_data.list[i].mxn_id);
							if(this.selectAreaType == 0) {
								this.ggtjOption.series[0].data.push(0);
								this.ggtjOption.series[1].data.push(0);
								this.ggtjOption.series[2].data.push(0);
								for(let j in _data.list[i].rdcSaleList) {
									if(_data.list[i].rdcSaleList[j].rdc == '北京') {
										this.ggtjOption.series[0].data[i] = _data.list[i].rdcSaleList[j].sale_amt;
									} else if(_data.list[i].rdcSaleList[j].rdc == '天津') {
										this.ggtjOption.series[1].data[i] = _data.list[i].rdcSaleList[j].sale_amt;
									} else if(_data.list[i].rdcSaleList[j].rdc == '广东') {
										this.ggtjOption.series[2].data[i] = _data.list[i].rdcSaleList[j].sale_amt;
									}
								}
							} else {
								this.ggtjOption.series[0].name = _data.list[i].rdcSaleList[0].rdc;
								this.ggtjOption.series[0].data.push(_data.list[i].rdcSaleList[0].sale_amt);
							}
						}
						if (this.ggtjOption.series[0].data.length == 0) {
							this.ggtjDataType = true;
						}
					} else if (_data.code == -200) {
						MessageBox.alert(_data.message).then(action => {
							this.delCookie('token')
						});
					} else {
						MessageBox('提示', _data.message);
					}
				}).catch(err => {
					if (!this.$axios.isCancel(err)) {
						this.ggtjloadingType = false;
						MessageBox('提示', '网络异常');
					}
				});
			},
            getsstj() {
                const CancelToken = this.$axios.CancelToken;
                if (typeof this.cancelAjax6 === 'function') {
                    this.cancelAjax6()
                }
                let timeStr = '';
                this.checkQueryIdx == 0 ? timeStr = null : timeStr = this.dateObj.paramsDate.toString();
                let that = this;
                this.sstjloadingType = true;
                this.sstjDataType = false;
                this.sstjOption.xAxis.data = [];
                this.sstjOption.series[0].data = [];
                
				this.$axios({
					method: "post",
					url: "web/GetLeagueSale",
					data: {
						token: this.token,
						dateType: this.checkQueryIdx,
						timeStr: timeStr,
					},
					cancelToken: new CancelToken(function executor (c) {
						that.cancelAjax6 = c
					})
				}).then(res => {
					this.sstjloadingType = false;
					let _data = res.data;
					if (_data.code == 0) {
						this.sstjResList = _data.list;
						for (let i in _data.list) {
							this.sstjOption.xAxis.data.push(_data.list[i].league_name);
							this.sstjOption.series[0].data.push(_data.list[i].sale_amt);
						}
						if (this.sstjOption.series[0].data.length == 0) {
							this.sstjDataType = true;
						}
					} else if (_data.code == -200) {
						MessageBox.alert(_data.message).then(action => {
							this.delCookie('token')
						});
					} else {
						MessageBox('提示', _data.message);
					}
				}).catch(err => {
					if (!this.$axios.isCancel(err)) {
						this.sstjloadingType = false;
						MessageBox('提示', '网络异常');
					}
				});
            },
            //查询游戏销售GetGameSale
            getGameSales () {
                const CancelToken = this.$axios.CancelToken;
                if (typeof this.cancelAjax5 === 'function') {
                    this.cancelAjax5()
                }
                let that = this;
                let timeStr = '';
                this.GameOptin.xAxis.data = [];
                this.GameOptin2.series[0].data = [];
                this.GameOptin.series[0].data = [];
                this.GameOptin.series[1].data = [];
                this.GameOptin.series[2].data = [];
                this.GameOptin.series[3].data = [];
                this.GameOptin.series[4].data = [];
                this.GameOptin.series[5].data = [];
                this.GameOptin.series[6].data = [];
                this.GameOptin2.series[0].data = [];
                this.colorList = [];
                this.checkQueryIdx == 0 ? timeStr = null : timeStr = this.dateObj.paramsDate.toString();
                this.GameloadingType = true;
                this.GameHasDataType = false;
                this.Game2HasDataType = false;
                if (this.selectSalesType == 2) {
                    this.$axios({
                        method: "post",
                        url: "web/GetDrawGameSale",
                        data: {
                            token: this.token,
                            dateType: this.checkQueryIdx,
                            timeStr: timeStr,
                            area: this.selectAreaType,
                        },
                        cancelToken: new CancelToken(function executor (c) {
                            that.cancelAjax5 = c
                        })
                    }).then(res => {
                        this.GameOptin2.series[0].data = [0, 0, 0, 0, 0, 0, 0];
                        let _data = res.data;
                        this.GameloadingType = false;
                        // '#F35B00', '#54A64E', '#9A968A', '#E7C00D', '#186DC9', '#4F9EFF'
                        if (_data.code == 0) {
                            let gameList = this.GameOptin2.series[0].data;
                            for (let i = 0; i < _data.game_List.length; i++) {
                                if (_data.game_List[i].game_name == ('下半场比分')) {
                                    this.GameOptin2.series[0].data[0] = _data.game_List[i].sale
                                }
                                if (_data.game_List[i].game_name == ('上下盘单双数')) {
                                    this.GameOptin2.series[0].data[1] = _data.game_List[i].sale
                                }
                                if (_data.game_List[i].game_name == ('胜负过关')) {
                                    this.GameOptin2.series[0].data[2] = _data.game_List[i].sale
                                }
                                if (_data.game_List[i].game_name == ('半全场胜平负')) {
                                    this.GameOptin2.series[0].data[3] = _data.game_List[i].sale
                                }
                                if (_data.game_List[i].game_name == ('总进球数')) {
                                    this.GameOptin2.series[0].data[4] = _data.game_List[i].sale
                                }
                                if (_data.game_List[i].game_name == ('单场比分')) {
                                    this.GameOptin2.series[0].data[5] = _data.game_List[i].sale
                                }
                                if (_data.game_List[i].game_name == ('胜平负')) {
                                    this.GameOptin2.series[0].data[6] = _data.game_List[i].sale
                                }
                            }
                            for (let i in _data.game_List) {
                                this.colorList.push(this.gameTransColor(_data.game_List[i].game_name));
                            }
                            this.colorList = this.colorList.reverse()
                            if (this.GameOptin2.series[0].data.length == 0) {
                                this.Game2HasDataType = true;
                            }
                            if (this.checkQueryIdx == 1) {
                                for (let i in _data.drawGameList) {
                                    for (let j in _data.drawGameList[i].list) {
                                        if (_data.drawGameList[i].list[j].game_id == 200) {
                                            this.GameOptin.series[0].data.push(_data.drawGameList[i].list[j].sale)
                                        } else if (_data.drawGameList[i].list[j].game_id == 210) {
                                            this.GameOptin.series[1].data.push(_data.drawGameList[i].list[j].sale)
                                        } else if (_data.drawGameList[i].list[j].game_id == 240) {
                                            this.GameOptin.series[2].data.push(_data.drawGameList[i].list[j].sale)
                                        } else if (_data.drawGameList[i].list[j].game_id == 230) {
                                            this.GameOptin.series[3].data.push(_data.drawGameList[i].list[j].sale)
                                        } else if (_data.drawGameList[i].list[j].game_id == 250) {
                                            this.GameOptin.series[4].data.push(_data.drawGameList[i].list[j].sale)
                                        } else if (_data.drawGameList[i].list[j].game_id == 270) {
                                            this.GameOptin.series[5].data.push(_data.drawGameList[i].list[j].sale)
                                        } else if (_data.drawGameList[i].list[j].game_id == 260) {
                                            this.GameOptin.series[6].data.push(_data.drawGameList[i].list[j].sale)
                                        }
                                    }
                                    this.GameOptin.xAxis.data.push(_data.drawGameList[i].drawNo)
                                }
                                if (_data.drawGameList.length == 0) {
                                    this.GameHasDataType = true;
                                }
                            } else {
                                for (let i in _data.dateGameList) {
                                    for (let j in _data.dateGameList[i].list) {
                                        if (_data.dateGameList[i].list[j].game_id == 200) {
                                            this.GameOptin.series[0].data.push(_data.dateGameList[i].list[j].sale)
                                        } else if (_data.dateGameList[i].list[j].game_id == 210) {
                                            this.GameOptin.series[1].data.push(_data.dateGameList[i].list[j].sale)
                                        } else if (_data.dateGameList[i].list[j].game_id == 240) {
                                            this.GameOptin.series[2].data.push(_data.dateGameList[i].list[j].sale)
                                        } else if (_data.dateGameList[i].list[j].game_id == 230) {
                                            this.GameOptin.series[3].data.push(_data.dateGameList[i].list[j].sale)
                                        } else if (_data.dateGameList[i].list[j].game_id == 250) {
                                            this.GameOptin.series[4].data.push(_data.dateGameList[i].list[j].sale)
                                        } else if (_data.dateGameList[i].list[j].game_id == 270) {
                                            this.GameOptin.series[5].data.push(_data.dateGameList[i].list[j].sale)
                                        } else if (_data.dateGameList[i].list[j].game_id == 260) {
                                            this.GameOptin.series[6].data.push(_data.dateGameList[i].list[j].sale)
                                        }
                                    }
                                    this.GameOptin.xAxis.data.push(this.changeVal(_data.dateGameList[i].rpt_date))
                                }
                                if (_data.dateGameList.length == 0) {
                                    this.GameHasDataType = true;
                                }
                            }

                        } else if (_data.code == -200) {
                            MessageBox.alert(_data.message).then(action => {
                                this.delCookie('token')
                            });
                        } else {
                            MessageBox('提示', _data.message);
                        }
                    }).catch(err => {
                        if (!this.$axios.isCancel(err)) {
                            MessageBox('提示', '网络异常');
                        }
                    });
                } else {
                    this.$axios({
                        method: "post",
                        url: "web/GetGameSale",
                        data: {
                            token: this.token,
                            dateType: this.checkQueryIdx,
                            timeStr: timeStr,
                            area: this.selectAreaType,
                            type: this.selectSalesType
                        },
                        cancelToken: new CancelToken(function executor (c) {
                            that.cancelAjax5 = c
                        })
                    }).then(res => {
                        this.GameOptin2.series[0].data = [0, 0, 0, 0, 0, 0, 0];
                        let _data = res.data;
                        this.GameloadingType = false;
                        // '#F35B00', '#54A64E', '#9A968A', '#E7C00D', '#186DC9', '#4F9EFF'
                        if (_data.code == 0) {
                            let gameList = this.GameOptin2.series[0].data;
                            for (let i = 0; i < _data.gameSaleList.length; i++) {
                                if (_data.gameSaleList[i].game_name == ('下半场比分')) {
                                    this.GameOptin2.series[0].data[0] = _data.gameSaleList[i].sale
                                }
                                if (_data.gameSaleList[i].game_name == ('上下盘单双数')) {
                                    this.GameOptin2.series[0].data[1] = _data.gameSaleList[i].sale
                                }
                                if (_data.gameSaleList[i].game_name == ('胜负过关')) {
                                    this.GameOptin2.series[0].data[2] = _data.gameSaleList[i].sale
                                }
                                if (_data.gameSaleList[i].game_name == ('半全场胜平负')) {
                                    this.GameOptin2.series[0].data[3] = _data.gameSaleList[i].sale
                                }
                                if (_data.gameSaleList[i].game_name == ('总进球数')) {
                                    this.GameOptin2.series[0].data[4] = _data.gameSaleList[i].sale
                                }
                                if (_data.gameSaleList[i].game_name == ('单场比分')) {
                                    this.GameOptin2.series[0].data[5] = _data.gameSaleList[i].sale
                                }
                                if (_data.gameSaleList[i].game_name == ('胜平负')) {
                                    this.GameOptin2.series[0].data[6] = _data.gameSaleList[i].sale
                                }
                            }
                            for (let i in _data.gameSaleList) {
                                this.colorList.push(this.gameTransColor(_data.gameSaleList[i].game_name));
                            }
                            this.colorList = this.colorList.reverse()
                            if (this.GameOptin2.series[0].data.length == 0) {
                                this.Game2HasDataType = true;
                            }
                            for (let i in _data.gameList) {
                                for (let j in _data.gameList[i].list) {
                                    if (_data.gameList[i].list[j].game_id == 200) {
                                        this.GameOptin.series[0].data.push(_data.gameList[i].list[j].sale)
                                    } else if (_data.gameList[i].list[j].game_id == 210) {
                                        this.GameOptin.series[1].data.push(_data.gameList[i].list[j].sale)
                                    } else if (_data.gameList[i].list[j].game_id == 240) {
                                        this.GameOptin.series[2].data.push(_data.gameList[i].list[j].sale)
                                    } else if (_data.gameList[i].list[j].game_id == 230) {
                                        this.GameOptin.series[3].data.push(_data.gameList[i].list[j].sale)
                                    } else if (_data.gameList[i].list[j].game_id == 250) {
                                        this.GameOptin.series[4].data.push(_data.gameList[i].list[j].sale)
                                    } else if (_data.gameList[i].list[j].game_id == 270) {
                                        this.GameOptin.series[5].data.push(_data.gameList[i].list[j].sale)
                                    } else if (_data.gameList[i].list[j].game_id == 260) {
                                        this.GameOptin.series[6].data.push(_data.gameList[i].list[j].sale)
                                    }
                                }
                                this.GameOptin.xAxis.data.push(this.changeVal(_data.gameList[i].rpt_date))
                            }
                            if (_data.gameList.length == 0) {
                                this.GameHasDataType = true;
                            }
                        } else if (_data.code == -200) {
                            MessageBox.alert(_data.message).then(action => {
                                this.delCookie('token')
                            });
                        } else {
                            MessageBox('提示', _data.message);
                        }
                    }).catch(err => {
                        if (!this.$axios.isCancel(err)) {
                            MessageBox('提示', '网络异常');
                        }
                    });
                }
			},
			//赛事统计，根据赛事名称查id
			getLeagueId(name) {
				for(let i in this.sstjResList){
					if(this.sstjResList[i].league_name == name) {
						return this.sstjResList[i].league_id;
					}
				}
			},
            gameTransColor (val) {
                switch (val) {
                    case '下半场比分':
                        return '#ab0c2c';
                    case '上下盘单双数':
                        return '#F35B00';
                    case '胜负过关':
                        return '#54A64E';
                    case '半全场胜平负':
                        return '#9A968A';
                    case '总进球数':
                        return '#E7C00D';
                    case '单场比分':
                        return '#186DC9';
                    case '胜平负':
                        return '#4F9EFF';
                    default:
                        return '#cccccc';
                }

            },
            // 日期转/
            changeVal (val) {
                let str = '';
                let result = '';
                if(val){
                    str = val.slice(5).replace('/', '-');
                }else{
                    str = ''
                }
                if (this.checkQueryIdx == 2) {
                    result = parseInt(str.split('-')[0]) + '月'
                } else {
                    result = parseInt(str.split('-')[0]) + '-' + parseInt(str.split('-')[1])
                }
                return result
            },
            //销售日期转事件
            checkSaleDate (date, num = 0) {
                let getDate = '';
                if (num == 1) {
                    if(date){
                        getDate = date.slice(5).replace('/', '-');
                    }else{
                        getDate = '';
                    }
                } else {
                    getDate = date;
                }
                let str = '';
                if (this.checkQueryIdx == 2) {
                    str = parseInt(getDate.split('-')[0]) + '月';
                } else {
                    str = parseInt(getDate.split('-')[0]) + '月' + parseInt(getDate.split('-')[1]) + '日';
                }
                return str
            },
            // 判断用户是否有全国权限
            checkQgParm () {
                this.$axios({
                    method: "post",
                    url: "web/GetUserPerm",
                    data: {
                        token: this.token,
                    }
                }).then(res => {
                    let _data = res.data;
                    if (_data.code == 0) {
                        let isPrem = _data.permList.join(',');
                        if (isPrem.indexOf('9') > -1) {
                            this.hasQgPerm = true;
                            if (isPrem.indexOf('7') > -1 || isPrem.indexOf('6') > -1 || isPrem.indexOf('8') > -1) {
                                this.selectAreaType = 1;
                                this.hasCenter = true;
                            } else {
                                this.selectAreaType = 0;
                                this.hasCenter = false;
                            }
                        } else {
                            this.hasQgPerm = false;
                            if (isPrem.indexOf('7') > -1 || isPrem.indexOf('6') > -1 || isPrem.indexOf('8') > -1) {
                                this.selectAreaType = 1;
                                this.hasCenter = true;
                            } else {
                                this.hasCenter = false;
                                MessageBox.alert(_data.message).then(action => {
                                    this.$router.push({path: '/'})
                                });
                            }
                        }
                        this.getSaleAmtAndCnt();
                        this.getSales();
                        this.getAreaSales();
                        this.getGameSales();
                        // this.getSalesRank();
						this.getsstj();
						this.getggtj();
                    } else if (_data.code == -200) {
                        MessageBox.alert(_data.message).then(action => {
                            this.delCookie('token')
                        });
                    } else {
                        MessageBox.alert(_data.message).then(action => {
                            this.delCookie('token')
                        });
                    }

                }).catch(err => {
                    if (!this.$axios.isCancel(err)) {
                        MessageBox('提示', '网络异常');
                    }
                });
            },
        },
        mounted () {
            let isPrem = this.getCookie('userPerm');
            this.checkQgParm();
        }
    };
</script>
<style scoped>
    @import "./../assets/css/sales.css";
    @import "./../assets/css/daily.css";

    .publicMoney {
        width: 100%;
        height: 6.43rem;
        border-bottom: 2.55rem solid rgba(239, 239, 239, 1);
    }

    .publicMoney li {
        width: 50%;
        text-align: center;
        line-height: 6.43rem;
    }

    .publicMoney li:nth-of-type(1) {
        font-size: 1.5rem;
    }

    .publicMoney li:nth-of-type(2) {
        text-align: left;
        text-indent: 1rem;
        font-size: 1.8rem;
        color: #FF0000;
        font-weight: bold;
    }

    .dataBox .dataBox_0 {
        height: auto;
    }

    .dataBox .chart_desc .name_city {
        width: 8rem;
    }

    .dataBox .chart_desc .num_city {
        width: 12rem;
        text-align: left;
    }

    .addr3 {
        background: #cccccc;
    }

    .num_percent {
        width: 6rem;
    }

    .text-center {
        text-align: center;
    }

    .dataBox .chart_desc div {
        line-height: 2.4rem;
        margin: 0 0 0 4rem;
    }
    .check-type-line{
        left: 50% !important;
    }
    .check-type-bottom-line{
        width: 16.4rem !important;
    }
    .check-run-0{
        left: 0%;
    }
    .check-run-1{
        left: 50%;
	}
	.ggtj_box{
		width: 37.5rem;
		height: 28.8rem;
	}
	.ggtj_box ul{
		width: 33.68rem;
		margin: 1.5rem auto 0;
	}
	.ggtj_box ul li{
		display: inline-block;
		background: #cccccc;
		width: 4.7rem;
		line-height: 2rem;
		border: 0.01rem solid #ccc;
		text-align: center;
	}
	.ggtj_box ul li.selected_active{
		background: #fff;
		border: 0.01rem solid #989696;
		border-bottom: 0.01rem solid #fff;
	}
	.salesMap {
        margin: 0 auto;
        z-index: 1;
        position: relative;
    }
    #salesMap2{
        overflow-x: auto;
    }

    .echarts {
		position: relative;
    }

    .loading {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 2;
    }

    .loading-wrap {
        margin-top: 25%;
    }

    .loading-wrap img {
        width: 5rem;
        height: 5rem;
        margin: 0 auto;
    }

    .loading-wrap p {
        font-size: 1.4rem;
        margin-top: 1rem;
        text-align: center;
    }
</style>